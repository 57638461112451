import globalComponents from "./globalComponents";
import globalDirectives from "./globalDirectives";
import globalMixins from "./globalMixins";
import VueLazyload from "vue-lazyload";

export default {
    install(Vue) {
        Vue.use(globalComponents);
        Vue.use(globalDirectives);
        Vue.use(globalMixins);
        Vue.use(VueLazyload, {
          observer: true,
          // optional
          observerOptions: {
            rootMargin: "0px",
            threshold: 0.1
          }
        });
    }
}