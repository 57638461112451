import { Dropdown } from "../components/material-kit";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "@/assets/scss/material-kit.scss";
import materialKitMixins from "./material-kit-mixins";
import VueGitHubButtons from "vue-github-buttons";
import "vue-github-buttons/dist/vue-github-buttons.css";

export default {
  install(Vue) {
    Vue.component("drop-down", Dropdown);
    Vue.use(VueMaterial);
    Vue.use(materialKitMixins);
    Vue.use(VueGitHubButtons, { useCache: false });
  }
};
